import { useState, useEffect } from 'react';

const timeOfAdSticky = 2000;

/**
 * Hook that tracks if an ad has been loaded and then make it sticky to the top edge for 2 seconds.
 * @param {object} adContainerRef - The reference to the ad we want to stick.
 * @returns boolean
 */
const useSetPrimaryAdSticky = (adContainerRef) => {
    const [adIsSticky, setAdIsSticky] = useState(false);

    useEffect(() => {
        let adTimer;
        let removeListenerTimer;
        const handleIframeLoad = () => {
            setAdIsSticky(true);
            adTimer = setTimeout(() => {
                setAdIsSticky(false);
            }, timeOfAdSticky + 1100); /* Including transition. */
        };

        const adContainer = adContainerRef.current;
        const observer = new MutationObserver(() => {
            const iFrame = adContainer.querySelector('iframe');
            if (iFrame) {
                iFrame.addEventListener('load', handleIframeLoad);
                removeListenerTimer = setTimeout(() => {
                    iFrame.removeEventListener('load', handleIframeLoad);
                }, 2 * timeOfAdSticky);
            }
        });

        if (adContainer)
            observer.observe(adContainer, { childList: true, subtree: true });

        return () => {
            clearTimeout(adTimer);
            clearTimeout(removeListenerTimer);
            observer.disconnect();
        };
    }, [adContainerRef]);

    return adIsSticky;
};

export default useSetPrimaryAdSticky;
