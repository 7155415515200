import { useEffect, useRef, useState } from 'react';
import Header from 'mfe-core/ui/Header';
import Title from 'components/Title/Title';
import PrimaryAd from 'components/PrimaryAd/PrimaryAd';
import ArticleWrapper from 'components/ArticleWrapper/ArticleWrapper';
import StandardHeadline from 'components/StandardHeadline/StandardHeadline';
import { setupParser } from 'mfe-articles-renderer';
import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';
import useUser from 'mfe-core/hooks/useUser';
import useSetPrimaryAdSticky from 'hooks/useSetPrimaryAdSticky/useSetPrimaryAdSticky';
import MainTopicLink from 'components/MainTopicLink/MainTopicLink';
import PageUtils from 'components/PageUtils/PageUtils';
import Hero from 'components/Hero/Hero';
import PublicationDate from 'components/PublicationDate/PublicationDate';
import Summary from 'components/Summary/Summary';
import DekBylineContainer from 'components/DekBylineContainer/DekBylineContainer';
import RelatedTopics from 'components/RelatedTopics/RelatedTopics';
import AuthorBioList from 'components/AuthorBioList/AuthorBioList';
import RigthRailAd from 'components/RightRailAd/RightRailAd';
import PianoAd from 'components/PianoAd/PianoAd';
import PersonalizationPlacement, {
    DATA_ANALYTICS_VALUES,
} from 'mfe-articles-shared/components/PersonalizationPlacement/PersonalizationPlacement';
import PrintLogo from 'mfe-articles-shared/components/PrintLogo/PrintLogo';
import usePaywallStore from 'mfe-articles-shared/stores/paywall/paywall.store';
import PianoPaywall from 'components/PianoPaywall/PianoPaywall';
import LimitCounter from 'components/LimitCounter/LimitCounter';
import TranslateMessage from 'components/TranslateMessage/TranslateMessage';
import RecommendedForYou from 'components/RecommendedForYou/RecommendedForYou';
import classNames from 'classnames';
import styles from './Standard.module.scss';

function Standard() {
    const article = useArticleStore((state) => state.article);
    const { profile } = useUser();
    const isPaywallEnabled = usePaywallStore((state) => state.isPaywallEnabled);
    const isPaywallLoading = usePaywallStore((state) => state.isPaywallLoading);
    const parseContent = setupParser();
    const parsedContent = parseContent(article.content);
    const [isVisible, setIsVisible] = useState(false);
    const rightRailRef = useRef(null);
    const articleHeaderRef = useRef(null);
    const paywallRef = useRef(null);
    const adContainerRef = useRef(null);
    const adIsSticky = useSetPrimaryAdSticky(adContainerRef);

    const showAdsRightRail = !isPaywallLoading && !isPaywallEnabled;

    const handleScrollCallback = (visible) => {
        setIsVisible(visible);
    };

    const primaryAdContainerClasses = classNames(
        styles['primary-ad-container'],
        {
            [styles['unstick-ad']]: !adIsSticky,
        },
    );

    useEffect(() => {
        if (isPaywallEnabled) {
            paywallRef.current = document.querySelector(
                '#paywall-creative-container',
            );
            if (
                paywallRef.current &&
                rightRailRef.current &&
                articleHeaderRef.current
            ) {
                const height =
                    paywallRef.current.getBoundingClientRect().top -
                    articleHeaderRef.current.getBoundingClientRect().bottom;
                rightRailRef.current.style.position = 'absolute';
                rightRailRef.current.style.height = `${height}px`;
                rightRailRef.current.style.overflow = 'hidden';
            }
        }
    }, [isPaywallEnabled, paywallRef.current?.getBoundingClientRect().top]);

    return (
        <>
            <div className={styles['top-container']}>
                <div className={primaryAdContainerClasses} ref={adContainerRef}>
                    <PrimaryAd />
                </div>
                <div className={styles['header-container']}>
                    <Header
                        showAd={false}
                        scrollCallback={handleScrollCallback}
                    >
                        <StandardHeadline isVisible={isVisible} />
                    </Header>
                </div>
            </div>

            <ArticleWrapper>
                <PrintLogo />
                <div className={styles.container}>
                    <div
                        className={styles['article-header']}
                        ref={articleHeaderRef}
                    >
                        <MainTopicLink />
                        <Title />
                        <DekBylineContainer />
                        <PublicationDate />
                    </div>
                    <div className={styles['hero-image']}>
                        <Hero />
                    </div>
                    <div className={styles['top-utils']}>
                        <PageUtils position={PageUtils.position.TOP} />
                    </div>
                    <div className={styles.summary}>
                        <Summary />
                        <TranslateMessage />
                    </div>
                    <div className={styles['left-rail']}>
                        <PageUtils position={PageUtils.position.LEFT} />
                    </div>
                    <div className={styles.content}>{parsedContent}</div>

                    <div className={styles['right-rail']} ref={rightRailRef}>
                        <div className={styles['right-rail-content']}>
                            <div
                                className={classNames(
                                    'ecommerce-module',
                                    styles['recommend-right'],
                                )}
                            >
                                <RecommendedForYou rightRail />
                            </div>
                            <div
                                className={classNames(
                                    styles['right-rail-middle-container'],
                                    'ecommerce-module',
                                )}
                            >
                                {showAdsRightRail && <PianoAd />}
                                <RigthRailAd />
                                {showAdsRightRail && (
                                    <PersonalizationPlacement
                                        name={
                                            DATA_ANALYTICS_VALUES.RAIL_RIGHT_3
                                        }
                                    />
                                )}
                            </div>
                            {showAdsRightRail && (
                                <div
                                    className={classNames(
                                        styles['right-rail-related-topics'],
                                    )}
                                >
                                    <RelatedTopics />
                                </div>
                            )}
                        </div>
                    </div>
                    {!isPaywallEnabled && (
                        <>
                            <div className={styles.author}>
                                <PublicationDate
                                    position={PublicationDate.variants.BOTTOM}
                                />
                                <PersonalizationPlacement
                                    name={DATA_ANALYTICS_VALUES.END_OF_ARTICLE}
                                />
                                <AuthorBioList />
                            </div>
                            <div className={styles['bottom-utils']}>
                                <PageUtils
                                    position={PageUtils.position.BOTTOM}
                                />
                            </div>
                            <div className={styles['bottom-related-topics']}>
                                <RelatedTopics />
                            </div>
                        </>
                    )}
                    <div className={styles.recommended}>
                        <RecommendedForYou />
                    </div>
                </div>
            </ArticleWrapper>
            <PianoPaywall />
            {profile?.state !== 'Subscriber' && <LimitCounter />}
        </>
    );
}

export default Standard;
