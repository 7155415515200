import { useState, useRef } from 'react';
import Header from 'mfe-core/ui/Header';
import Title from 'components/Title/Title';
import PrimaryAd from 'components/PrimaryAd/PrimaryAd';
import ArticleWrapper from 'components/ArticleWrapper/ArticleWrapper';
import SpotlightHeadline from 'components/SpotlightHeadline/SpotlightHeadline';
import { setupParser } from 'mfe-articles-renderer';
import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';
import usePaywallStore from 'mfe-articles-shared/stores/paywall/paywall.store';
import useUser from 'mfe-core/hooks/useUser';
import useSetPrimaryAdSticky from 'hooks/useSetPrimaryAdSticky/useSetPrimaryAdSticky';
import MainTopicLink from 'components/MainTopicLink/MainTopicLink';
import PageUtils from 'components/PageUtils/PageUtils';
import Hero from 'components/Hero/Hero';
import PublicationDate from 'components/PublicationDate/PublicationDate';
import Summary from 'components/Summary/Summary';
import DekBylineContainer from 'components/DekBylineContainer/DekBylineContainer';
import RelatedTopics from 'components/RelatedTopics/RelatedTopics';
import TopSeriesNav from 'components/TopSeriesNav/TopSeriesNav';
import BottomSeriesNav from 'components/BottomSeriesNav/BottomSeriesNav';
import AuthorBioList from 'components/AuthorBioList/AuthorBioList';
import PersonalizationPlacement, {
    DATA_ANALYTICS_VALUES,
} from 'mfe-articles-shared/components/PersonalizationPlacement/PersonalizationPlacement';
import PrintLogo from 'mfe-articles-shared/components/PrintLogo/PrintLogo';
import PianoPaywall from 'components/PianoPaywall/PianoPaywall';
import RecommendedForYou from 'components/RecommendedForYou/RecommendedForYou';
import LimitCounter from 'components/LimitCounter/LimitCounter';
import classNames from 'classnames';
import styles from './Spotlight.module.scss';

function Spotlight() {
    const article = useArticleStore((state) => state.article);
    const { profile } = useUser();
    const isPaywallEnabled = usePaywallStore((state) => state.isPaywallEnabled);
    const parseContent = setupParser();
    const parsedContent = parseContent(article.content);
    const [isVisible, setIsVisible] = useState(false);
    const adContainerRef = useRef(null);
    const adIsSticky = useSetPrimaryAdSticky(adContainerRef);

    const handleScrollCallback = (visible) => {
        setIsVisible(visible);
    };

    const primaryAdContainerClasses = classNames(
        styles['primary-ad-container'],
        {
            [styles['unstick-ad']]: !adIsSticky,
        },
    );

    return (
        <>
            <div className={styles['top-container']}>
                <div className={primaryAdContainerClasses} ref={adContainerRef}>
                    <PrimaryAd />
                </div>
                <div className={styles['header-container']}>
                    <Header
                        showAd={false}
                        scrollCallback={handleScrollCallback}
                    >
                        <SpotlightHeadline isVisible={isVisible} />
                    </Header>
                </div>
            </div>

            <ArticleWrapper>
                <PrintLogo />
                <div className={styles.container}>
                    <div className={styles['article-header']}>
                        <MainTopicLink />
                        <Title />
                        <DekBylineContainer />
                        <PublicationDate />
                    </div>
                    <div className={styles['hero-image']}>
                        <Hero />
                    </div>
                    <div className={styles['top-series-nav']}>
                        <TopSeriesNav />
                    </div>
                    <div className={styles.summary}>
                        <Summary />
                    </div>
                    <div className={styles['left-rail']}>
                        <PageUtils position={PageUtils.position.LEFT} />
                    </div>
                    <div className={styles.content}>
                        <div>{parsedContent}</div>
                    </div>
                    {!isPaywallEnabled && (
                        <>
                            <div className={styles['right-rail']}>
                                <RelatedTopics />
                            </div>
                            <div className={styles.author}>
                                <PublicationDate
                                    position={PublicationDate.variants.BOTTOM}
                                />
                                <PersonalizationPlacement
                                    name={DATA_ANALYTICS_VALUES.END_OF_ARTICLE}
                                />
                                <AuthorBioList />
                            </div>
                            <div className={styles['bottom-utils']}>
                                <PageUtils
                                    position={PageUtils.position.BOTTOM}
                                />
                            </div>

                            <div className={styles['bottom-series-nav']}>
                                <BottomSeriesNav />
                            </div>
                            <div className={styles['bottom-related-topics']}>
                                <RelatedTopics />
                            </div>
                        </>
                    )}
                </div>
            </ArticleWrapper>
            <PianoPaywall />
            {profile?.state !== 'Subscriber' && <LimitCounter />}
            <RecommendedForYou />
        </>
    );
}

export default Spotlight;
